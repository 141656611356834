import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios, { Axios } from 'axios'
import VueAxios from 'vue-axios'
import useVuelidate from '@vuelidate/core'
import store from './store'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

axios.defaults.baseURL = 'https://apipapime.000webhostapp.com/apipapime/public/'

createApp(App).use(VueAxios, axios).use(router).use(useVuelidate).use(store).component('font-awesome-icon', FontAwesomeIcon).mount('#app')

