import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
    import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    children: [
      {
        path: "",
        name: "Inicio",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Temas.vue"),
      },
      {
        path: "LoginProfesor",
        name: "LoginProfesor",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Profesor/LoginProfesor.vue"
          ),
      },
      {
        path: "PanelControlProfesor",
        name: "PanelProfesor",
        component: () => import( /* webpackChunkName: "about" */ "../views/Profesor/PanelControlProfesor.vue")
      },
      {
        path: "ConfigJuego",
        name: "ConfigJuego",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Profesor/ConfigJuego.vue"
          ),
          children: [
            {
              path: "",
              name: "ConfigJuegoProfesor",
              component: () =>
                import(
                  /* webpackChunkName: "about" */ "../views/Profesor/ConfigJuegoProfesor.vue"
                ),
            },
            {
              path: "ConfigSala1",
              name: "ConfigSala1",
              component: () =>
                import(
                  /* webpackChunkName: "about" */ "../views/Profesor/ConfigSala1.vue"
                ),
            },
            {
              path: "ConfigSala2",
              name: "ConfigSala2",
              component: () =>
                import(
                  /* webpackChunkName: "about" */ "../views/Profesor/ConfigSala2.vue"
                ),
            },
            {
              path: "ConfigSala3",
              name: "ConfigSala3",
              component: () =>
                import(
                  /* webpackChunkName: "about" */ "../views/Profesor/ConfigSala3.vue"
                ),
            },
            {
              path: "ConfigSala4",
              name: "ConfigSala4",
              component: () =>
                import(
                  /* webpackChunkName: "about" */ "../views/Profesor/ConfigSala4.vue"
                ),
            },
            {
              path: "FinCrearSala",
              name: "FinCrearSala",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/Profesor/FinCrearSala.vue")
            },

          ]
      },
      {
        path: "Creditos",
        name: "Creditos",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Creditos.vue"),
      },
      {
        path: "AvisosLegales",
        name: "AvisosLegales",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AvisosLegales.vue"),
      },
      {
        path: "AvisosDePrivacidad",
        name: "AvisosDePrivacidad",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AvisosPrivacidad.vue"),
      },
      {
        path: "AcercaDePapime",
        name: "AcercaDePapime",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/AcercaDePapime.vue"),
      },
      {
        path: "TratamientoAguasResiduales",
        name: "TratamientoAguasResiduales",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/TratamientosAguasResiduales.vue"),
          children:[
            {
              path: "",
              name: "IntroduccionTratamientoAguasResiduales",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Introduccion.vue"),
            },
            {
              path: "Situacion1",
              name: "Situacion1",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion1.vue"),
            },
            {
              path: "Situacion1-Objetivo",
              name: "Situacion1Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion1_Objetivo.vue"),
            },
            {
              path: "Situacion1-Recursos",
              name: "Situacion1Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion1_Recursos.vue"),
            },
            {
              path: "Situacion1-ActividadesDocente",
              name: "Situacion1ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion1_ActividadesDocente.vue"),
            },
            {
              path: "Situacion1-ActividadesEstudiante",
              name: "Situacion1ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion1_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion1-LigaJuego",
              name: "Situacion1LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion1_LigaJuego.vue"),
            },
            {
              path: "Situacion1-Evidencias",
              name: "Situacion1Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion1_Evidencias.vue"),
            },
            {
              path: "Situacion1-Evaluacion",
              name: "Situacion1Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion1_Evaluacion.vue"),
            },
            {
              path: "Situacion1-EvaluacionExperiencia",
              name: "Situacion1EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion1_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion2",
              name: "Situacion2",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion2.vue"),
            },
            {
              path: "Situacion2-Objetivo",
              name: "Situacion2Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion2_Objetivo.vue"),
            },
            {
              path: "Situacion2-Recursos",
              name: "Situacion2Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion2_Recursos.vue"),
            },
            {
              path: "Situacion2-ActividadesDocente",
              name: "Situacion2ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion2_ActividadesDocente.vue"),
            },
            {
              path: "Situacion2-ActividadesEstudiante",
              name: "Situacion2ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion2_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion2-LigaJuego",
              name: "Situacion2LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion2_LigaJuego.vue"),
            },
            {
              path: "Situacion2-Evidencias",
              name: "Situacion2Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion2_Evidencias.vue"),
            },
            {
              path: "Situacion2-Evaluacion",
              name: "Situacion2Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion2_Evaluacion.vue"),
            },
            {
              path: "Situacion2-EvaluacionExperiencia",
              name: "Situacion2EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion2_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion3",
              name: "Situacion3",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion3.vue"),
            },
            {
              path: "Situacion3-Objetivo",
              name: "Situacion3Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion3_Objetivo.vue"),
            },
            {
              path: "Situacion3-Recursos",
              name: "Situacion3Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion3_Recursos.vue"),
            },
            {
              path: "Situacion3-ActividadesDocente",
              name: "Situacion3ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion3_ActividadesDocente.vue"),
            },
            {
              path: "Situacion3-ActividadesEstudiante",
              name: "Situacion3ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion3_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion3-LigaJuego",
              name: "Situacion3LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion3_LigaJuego.vue"),
            },
            {
              path: "Situacion3-Evidencias",
              name: "Situacion3Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion3_Evidencias.vue"),
            },
            {
              path: "Situacion3-Evaluacion",
              name: "Situacion3Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion3_Evaluacion.vue"),
            },
            {
              path: "Situacion3-EvaluacionExperiencia",
              name: "Situacion3EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/TratamientoAguasResiduales/Situacion3_EvaluacionExperiencia.vue"),
            },
          ]
      },
      {
        path: "ContaminaciondelAgua",
        name: "ContaminaciondelAgua",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/ContaminacionDelAgua.vue"),
          children:[
            {
              path: "",
              name: "IntroduccionContaminacionDelAgua",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Introduccion.vue"),
            },
            {
              path: "Situacion1",
              name: "ContaminacionDelAguaSituacion1",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion1.vue"),
            },
            {
              path: "Situacion1-Objetivo",
              name: "ContaminacionDelAguaSituacion1Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion1_Objetivo.vue"),
            },
            {
              path: "Situacion1-Recursos",
              name: "ContaminacionDelAguaSituacion1Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion1_Recursos.vue"),
            },
            {
              path: "Situacion1-ActividadesDocente",
              name: "ContaminacionDelAguaSituacion1ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion1_ActividadesDocente.vue"),
            },
            {
              path: "Situacion1-ActividadesEstudiante",
              name: "ContaminacionDelAguaSituacion1ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion1_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion1-LigaJuego",
              name: "ContaminacionDelAguaSituacion1LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion1_LigaJuego.vue"),
            },
            {
              path: "Situacion1-Evidencias",
              name: "ContaminacionDelAguaSituacion1Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion1_Evidencias.vue"),
            },
            {
              path: "Situacion1-Evaluacion",
              name: "ContaminacionDelAguaSituacion1Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion1_Evaluacion.vue"),
            },
            {
              path: "Situacion1-EvaluacionExperiencia",
              name: "ContaminacionDelAguaSituacion1EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion1_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion1-Referencias",
              name: "ContaminacionDelAguaSituacion1Referencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion1_Referencias.vue"),
            },
            {
              path: "Situacion2",
              name: "ContaminacionDelAguaSituacion2",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion2.vue"),
            },
            {
              path: "Situacion2-Objetivo",
              name: "ContaminacionDelAguaSituacion2Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion2_Objetivo.vue"),
            },
            {
              path: "Situacion2-Recursos",
              name: "ContaminacionDelAguaSituacion2Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion2_Recursos.vue"),
            },
            {
              path: "Situacion2-ActividadesDocente",
              name: "ContaminacionDelAguaSituacion2ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion2_ActividadesDocente.vue"),
            },
            {
              path: "Situacion2-ActividadesEstudiante",
              name: "ContaminacionDelAguaSituacion2ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion2_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion2-LigaJuego",
              name: "ContaminacionDelAguaSituacion2LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion2_LigaJuego.vue"),
            },
            {
              path: "Situacion2-Evidencias",
              name: "ContaminacionDelAguaSituacion2Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion2_Evidencias.vue"),
            },
            {
              path: "Situacion2-Evaluacion",
              name: "ContaminacionDelAguaSituacion2Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion2_Evaluacion.vue"),
            },
            {
              path: "Situacion2-EvaluacionExperiencia",
              name: "ContaminacionDelAguaSituacion2EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion2_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion2-Referencias",
              name: "ContaminacionDelAguaSituacion2Referencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion2_Referencias.vue"),
            },
            {
              path: "Situacion3",
              name: "ContaminacionDelAguaSituacion3",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion3.vue"),
            },
            {
              path: "Situacion3-Objetivo",
              name: "ContaminacionDelAguaSituacion3Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion3_Objetivo.vue"),
            },
            {
              path: "Situacion3-Recursos",
              name: "ContaminacionDelAguaSituacion3Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion3_Recursos.vue"),
            },
            {
              path: "Situacion3-ActividadesDocente",
              name: "ContaminacionDelAguaSituacion3ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion3_ActividadesDocente.vue"),
            },
            {
              path: "Situacion3-ActividadesEstudiante",
              name: "ContaminacionDelAguaSituacion3ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion3_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion3-LigaJuego",
              name: "ContaminacionDelAguaSituacion3LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion3_LigaJuego.vue"),
            },
            {
              path: "Situacion3-Evidencias",
              name: "ContaminacionDelAguaSituacion3Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion3_Evidencias.vue"),
            },
            {
              path: "Situacion3-Evaluacion",
              name: "ContaminacionDelAguaSituacion3Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion3_Evaluacion.vue"),
            },
            {
              path: "Situacion3-EvaluacionExperiencia",
              name: "ContaminacionDelAguaSituacion3EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion3_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion4",
              name: "ContaminacionDelAguaSituacion4",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion4.vue"),
            },
            {
              path: "Situacion4-Objetivo",
              name: "ContaminacionDelAguaSituacion4Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion4_Objetivo.vue"),
            },
            {
              path: "Situacion4-Recursos",
              name: "ContaminacionDelAguaSituacion4Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion4_Recursos.vue"),
            },
            {
              path: "Situacion4-ActividadesDocente",
              name: "ContaminacionDelAguaSituacion4ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion4_ActividadesDocente.vue"),
            },
            {
              path: "Situacion4-ActividadesEstudiante",
              name: "ContaminacionDelAguaSituacion4ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion4_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion4-LigaJuego",
              name: "ContaminacionDelAguaSituacion4LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion4_LigaJuego.vue"),
            },
            {
              path: "Situacion4-Evidencias",
              name: "ContaminacionDelAguaSituacion4Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion4_Evidencias.vue"),
            },
            {
              path: "Situacion4-Evaluacion",
              name: "ContaminacionDelAguaSituacion4Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion4_Evaluacion.vue"),
            },
            {
              path: "Situacion4-EvaluacionExperiencia",
              name: "ContaminacionDelAguaSituacion4EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion4_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion4-Referencias",
              name: "ContaminacionDelAguaSituacion4Referencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ContaminacionDelAgua/Situacion4_Referencias.vue"),
            },
          ]
      },
      {
        path: "ImpactoAmbiental",
        name: "ImpactoAmbiental",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/ImpactoAmbiental.vue"),
          children:[
            {
              path: "",
              name: "IntroduccionImpactoAmbiental",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Introduccion.vue"),
            },
            {
              path: "Situacion1",
              name: "ImpactoAmbientalSituacion1",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion1.vue"),
            },
            {
              path: "Situacion1-Objetivo",
              name: "ImpactoAmbientalSituacion1Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion1_Objetivo.vue"),
            },
            {
              path: "Situacion1-Recursos",
              name: "ImpactoAmbientalSituacion1Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion1_Recursos.vue"),
            },
            {
              path: "Situacion1-ActividadesDocente",
              name: "ImpactoAmbientalSituacion1ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion1_ActividadesDocente.vue"),
            },
            {
              path: "Situacion1-ActividadesEstudiante",
              name: "ImpactoAmbientalSituacion1ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion1_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion1-LigaJuego",
              name: "ImpactoAmbientalSituacion1LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion1_LigaJuego.vue"),
            },
            {
              path: "Situacion1-Evidencias",
              name: "ImpactoAmbientalSituacion1Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion1_Evidencias.vue"),
            },
            {
              path: "Situacion1-Evaluacion",
              name: "ImpactoAmbientalSituacion1Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion1_Evaluacion.vue"),
            },
            {
              path: "Situacion1-EvaluacionExperiencia",
              name: "ImpactoAmbientalSituacion1EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion1_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion2",
              name: "ImpactoAmbientalSituacion2",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion2.vue"),
            },
            {
              path: "Situacion2-Objetivo",
              name: "ImpactoAmbientalSituacion2Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion2_Objetivo.vue"),
            },
            {
              path: "Situacion2-Recursos",
              name: "ImpactoAmbientalSituacion2Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion2_Recursos.vue"),
            },
            {
              path: "Situacion2-ActividadesDocente",
              name: "ImpactoAmbientalSituacion2ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion2_ActividadesDocente.vue"),
            },
            {
              path: "Situacion2-ActividadesEstudiante",
              name: "ImpactoAmbientalSituacion2ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion2_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion2-LigaJuego",
              name: "ImpactoAmbientalSituacion2LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion2_LigaJuego.vue"),
            },
            {
              path: "Situacion2-Evidencias",
              name: "ImpactoAmbientalSituacion2Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion2_Evidencias.vue"),
            },
            {
              path: "Situacion2-Evaluacion",
              name: "ImpactoAmbientalSituacion2Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion2_Evaluacion.vue"),
            },
            {
              path: "Situacion2-EvaluacionExperiencia",
              name: "ImpactoAmbientalSituacion2EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion2_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion3",
              name: "ImpactoAmbientalSituacion3",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion3.vue"),
            },
            {
              path: "Situacion3-Objetivo",
              name: "ImpactoAmbientalSituacion3Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion3_Objetivo.vue"),
            },
            {
              path: "Situacion3-Recursos",
              name: "ImpactoAmbientalSituacion3Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion3_Recursos.vue"),
            },
            {
              path: "Situacion3-ActividadesDocente",
              name: "ImpactoAmbientalSituacion3ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion3_ActividadesDocente.vue"),
            },
            {
              path: "Situacion3-LigaJuego",
              name: "ImpactoAmbientalSituacion3LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion3_LigaJuego.vue"),
            },
            {
              path: "Situacion3-Evaluacion",
              name: "ImpactoAmbientalSituacion3Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion3_Evaluacion.vue"),
            },
            {
              path: "Situacion3-Referencias",
              name: "ImpactoAmbientalSituacion3Referencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ImpactoAmbiental/Situacion3_Referencias.vue"),
            },
          ]
      },
      {
        path: "ResiduosSolidos",
        name: "ResiduosSolidos",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/ResiduosSolidos.vue"),
          children:[
            {
              path: "",
              name: "IntroduccionResiduosSolidos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Introduccion.vue"),
            },
            {
              path: "Situacion1",
              name: "ResiduosSolidosSituacion1",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion1.vue"),
            },
            {
              path: "Situacion1-Objetivo",
              name: "ResiduosSolidosSituacion1Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion1_Objetivo.vue"),
            },
            {
              path: "Situacion1-Recursos",
              name: "ResiduosSolidosSituacion1Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion1_Recursos.vue"),
            },
            {
              path: "Situacion1-ActividadesDocente",
              name: "ResiduosSolidosSituacion1ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion1_ActividadesDocente.vue"),
            },
            {
              path: "Situacion1-ActividadesEstudiante",
              name: "ResiduosSolidosSituacion1ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion1_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion1-LigaJuego",
              name: "ResiduosSolidosSituacion1LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion1_LigaJuego.vue"),
            },
            {
              path: "Situacion1-Evidencias",
              name: "ResiduosSolidosSituacion1Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion1_Evidencias.vue"),
            },
            {
              path: "Situacion1-Evaluacion",
              name: "ResiduosSolidosSituacion1Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion1_Evaluacion.vue"),
            },
            {
              path: "Situacion1-EvaluacionExperiencia",
              name: "ResiduosSolidosSituacion1EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion1_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion2",
              name: "ResiduosSolidosSituacion2",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion2.vue"),
            },
            {
              path: "Situacion2-Objetivo",
              name: "ResiduosSolidosSituacion2Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion2_Objetivo.vue"),
            },
            {
              path: "Situacion2-Recursos",
              name: "ResiduosSolidosSituacion2Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion2_Recursos.vue"),
            },
            {
              path: "Situacion2-ActividadesDocente",
              name: "ResiduosSolidosSituacion2ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion2_ActividadesDocente.vue"),
            },
            {
              path: "Situacion2-ActividadesEstudiante",
              name: "ResiduosSolidosSituacion2ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion2_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion2-LigaJuego",
              name: "ResiduosSolidosSituacion2LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion2_LigaJuego.vue"),
            },
            {
              path: "Situacion2-Evidencias",
              name: "ResiduosSolidosSituacion2Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion2_Evidencias.vue"),
            },
            {
              path: "Situacion2-Evaluacion",
              name: "ResiduosSolidosSituacion2Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion2_Evaluacion.vue"),
            },
            {
              path: "Situacion2-EvaluacionExperiencia",
              name: "ResiduosSolidosSituacion2EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion2_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion3",
              name: "ResiduosSolidosSituacion3",
              component: () =>
              import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3.vue"),
            },
            {
              path: "Situacion3-Objetivo",
              name: "ResiduosSolidosSituacion3Objetivo",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3_Objetivo.vue"),
            },
            {
              path: "Situacion3-Recursos",
              name: "ResiduosSolidosSituacion3Recursos",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3_Recursos.vue"),
            },
            {
              path: "Situacion3-ActividadesDocente",
              name: "ResiduosSolidosSituacion3ActividadesDocente",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3_ActividadesDocente.vue"),
            },
            {
              path: "Situacion3-ActividadesEstudiante",
              name: "ResiduosSolidosSituacion3ActividadesEstudiante",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3_ActividadesEstudiante.vue"),
            },
            {
              path: "Situacion3-LigaJuego",
              name: "ResiduosSolidosSituacion3LigaJuego",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3_LigaJuego.vue"),
            },
            {
              path: "Situacion3-Evidencias",
              name: "ResiduosSolidosSituacion3Evidencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3_Evidencias.vue"),
            },
            {
              path: "Situacion3-Evaluacion",
              name: "ResiduosSolidosSituacion3Evaluacion",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3_Evaluacion.vue"),
            },
            {
              path: "Situacion3-EvaluacionExperiencia",
              name: "ResiduosSolidosSituacion3EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3_EvaluacionExperiencia.vue"),
            },
            {
              path: "Situacion3-EvaluacionExperiencia",
              name: "ResiduosSolidosSituacion3EvaluacionExperiencia",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3_EvaluacionExperiencia.vue"),
            },                        
            {
              path: "Situacion3-Referencias",
              name: "ResiduosSolidosSituacion3Referencias",
              component: () =>
                import(/* webpackChunkName: "about" */ "../views/SituacionesEnsenanza/ResiduosSolidos/Situacion3_Referencias.vue"),
            },
          ]
      },
    ],
  },
  {
    path: "/Juego",
    name: "Juego",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Alumno/Juego.vue"
      ),
  },
  {
    path: "/LoginAdmin",
    name: "LoginAdmin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Administrador/LoginAdmin.vue"
      ),
  },
  {
    path: "/PanelAdmin",
    name: "Admin",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/Administrador/PanelAdmin.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
