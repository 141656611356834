<template>

  <!-- contenedor-->
  <div class="app">
    <router-view></router-view>  
  </div>
  
</template>

<script>
  export default{
  }
  
</script>

<style>
  @import url(../css/app.css);
</style>

