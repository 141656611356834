import { createStore } from 'vuex'

export default createStore({
  state: {
    payload: {
      token: localStorage.getItem('token_profesor'),
      group_num:"",
      teaching_situation:"",
      course:"Ingeniería Civil",
      session_name:"",
      game:{
        sala1:{
          name: "Biblioteca",
          num_questions: 0,
          has_images: false,
          questions: []
        },
        sala2:{
          name: "Canchas",
          num_questions: "",
          has_images: false,
          questions: []
        },
        sala3:{
          name: "Donde sea",
          num_questions: 0,
          has_images: false,
          questions: []
        },
        sala4:{
          name: "En mi casa",
          num_questions: 0,
          has_images: false,
          questions: []
        },
      }
    },
    pageView: {
      sala1:"sala",
      sala2:"sala",
      sala3:"sala",
      sala4:"sala",
    },
    isValidateQuestions: true
  },
  getters: {
    getConfigGame(state){
      return state.payload;
    },
    getNumQuestionsSala1(state){
      return state.payload.game.sala1.num_questions;
    },
    getNumQuestionsSala2(state){
      return state.payload.game.sala2.num_questions;
    },
    getNumQuestionsSala3(state){
      return state.payload.game.sala3.num_questions;
    },
    getNumQuestionsSala4(state){
      return state.payload.game.sala4.num_questions;
    },
    getConfigSala4(state){
      return state.payload.game.sala4;
    },
    getQuestionsSala1(state){
      return state.payload.game.sala1.questions;
    },
    getQuestionsSala2(state){
      return state.payload.game.sala2.questions;
    },
    getQuestionsSala3(state){
      return state.payload.game.sala3.questions;
    },
    getQuestionsSala4(state){
      return state.payload.game.sala4.questions;
    },
    getPageViewSala1(state){
      return state.pageView.sala1;
    },
    getPageViewSala2(state){
      return state.pageView.sala2;
    },
    getPageViewSala3(state){
      return state.pageView.sala3;
    },
    getPageViewSala4(state){
      return state.pageView.sala4;
    },
    getIsValidateQuestions(state){
      return state.isValidateQuestions;
    }
  },
  mutations: {
    addValuesPayload(state){
      state.payload.token = state.payload.token;
      state.payload.group_num = state.payload.group_num;
      state.payload.teaching_situation = state.payload.teaching_situation;
      state.payload.session_name = state.payload.session_name;
      state.payload.game = state.payload.game;
      state.payload.game.sala1 = state.payload.game.sala1;
      state.payload.game.sala2 = state.payload.game.sala2;
      state.payload.game.sala3 = state.payload.game.sala3;
      state.payload.game.sala4 = state.payload.game.sala4;
      state.payload.game.sala1.num_questions = state.payload.game.sala1.num_questions;
      state.payload.game.sala2.num_questions = state.payload.game.sala2.num_questions;
      state.payload.game.sala3.num_questions = state.payload.game.sala3.num_questions;
      state.payload.game.sala4.num_questions = state.payload.game.sala4.num_questions;
      state.payload.game.sala1.questions = state.payload.game.sala1.questions;
      state.payload.game.sala2.questions = state.payload.game.sala2.questions;
      state.payload.game.sala3.questions = state.payload.game.sala3.questions;
      state.payload.game.sala4.questions = state.payload.game.sala4.questions;
      state.pageView.sala1 = state.pageView.sala1;
      state.pageView.sala2 = state.pageView.sala2;
      state.pageView.sala3 = state.pageView.sala3;
      state.pageView.sala4 = state.pageView.sala4;
      state.isValidateQuestions = state.isValidateQuestions;
    }
  },    
  actions: {
    addValuesPayloadAction(context){
      context.commit('addValuesPayload');
    }
  },
  modules: {
  }
})
